import type { NextPage } from "next";
import React from "react";
// import Home from "@/pages/home/index";

// import PartnerManagement from "@/pages/clients/partnerManagement";
// import CustomerManagement from "@/pages/clients/customerManagement";
// Type error: Cannot find module '@/pages/clients/partnerManagement' or its corresponding type declarations.
// const PartnerManagement = dynamic(() => import("@/pages/clients/partnerManagement"));
// const CustomerManagement = dynamic(() => import("@/pages/clients/customerManagement"));

const IndexPage: NextPage = () => {
  let _component = <> </>; //<Home />;
  // if (userAuth == USER_ROLE.ROLE_PARTNER) {
  //   _component = <PartnerManagement />;
  // }
  // if (userAuth == USER_ROLE.ROLE_PARTNER) {
  //   _component = <CustomerManagement />;
  // }

  return <>{_component}</>;
};

export default IndexPage;
